// Variable overrides first

@import "./Agency.scss";


.payicon{
    width:32px;
}

.payphone{
    width:20px;
}


  


.heartfill {
fill:$primary;
}

.checkmark__circle {
	stroke-dasharray: 166;
	stroke-dashoffset: 166;
	stroke-width: 2;
	stroke-miterlimit: 10;
	stroke: $success;
	fill: none;
	animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }
  .checkmark {
	width: 56px;
	height: 56px;
	border-radius: 50%;
	display: block;
	stroke-width: 2;
	stroke: #fff;
	stroke-miterlimit: 10;
	margin: 10% auto;
	box-shadow: inset 0px 0px 0px $success;
	animation: fill 0.4s ease-in-out 0.4s forwards,
	  scale 0.3s ease-in-out 0.9s both;
  }
  .checkmark__check {
	transform-origin: 50% 50%;
	stroke-dasharray: 48;
	stroke-dashoffset: 48;
	animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }
  @keyframes stroke {
	100% {
	  stroke-dashoffset: 0;
	}
  }
  @keyframes scale {
	0%,
	100% {
	  transform: none;
	}
	50% {
	  transform: scale3d(1.1, 1.1, 1);
	}
  }
  @keyframes fill {
	100% {
	  box-shadow: inset 0px 0px 0px 30px $success;
	}
  }

.companybutton {
	background-color: $secondary-border-subtle;
	border-radius: 5px;
	padding-left: 5px;
	padding-right: 5px;
	cursor: pointer;
};
.smallbutton {
	background-color: $success-bg-subtle;
	border-radius: 5px;
	padding-left: 5px;
	padding-right: 5px;
	cursor: pointer;
};
.optionbutton {
	background-color: $warning-bg-subtle;
	border-radius: 5px;
	padding-left: 5px;
	padding-right: 5px;
	cursor: pointer;
};
.selectedbutton {
	background-color: $success-border-subtle;
	border-radius: 5px;
	padding-left: 5px;
	padding-right: 5px;
	cursor: pointer;
};
